import React from 'react';
import "./css/editor-style.css";

import { Container, Row, Col } from 'reactstrap';

export const JSEditor = () => {
 return (
    <Container>
	    <div class="row">
		    <div class="col col-lg-4">
		    </div>
		      <div class="col-md-auto">
		       	<div>
    			Note: Place MS-Word text here...<br />
    
					<div id="main" class="_dt-no-rows">

						<div id="editor" contenteditable="true"></div>
					    
					    <div id="bodytext" contenteditable="true"></div>

					</div>
    			<p></p>
  				</div>
		    </div>
		    <div class="col col-lg-4">
		    </div>
	  	</div>
        

    </Container>
  );
}

