import React from 'react';
import { Link } from 'react-router-dom';
import '../css/navbar.css';

export const NavigationBar = () => (
  <nav className="navbar navbar-expand-lg navbar-light navbar__link" style={{backgroundColor: "#81a1cf"}}>
        <p className="shine"><a className="shine" href="https://appdeveloperweb.com">AppDeveloperWeb.Com</a></p>
        <button className="navbar-toggler ml-auto hidden-sm-up float-xs-right:focus border-0 border-dark" 
                type="button" 
                data-toggle="collapse" 
                data-target="#navbarSupportedContent" 
                aria-controls="navbarSupportedContent" 
                aria-expanded="false" 
                aria-label="Toggle navigation">
          <span className="navbar-toggler-icon border-0 border-dark"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="nav-martin mr-auto">
            <li className="nav-martin">
              <Link to="/about">About</Link>
            </li>
            <li className="nav-martin">
              <Link to="/services">Services</Link>
            </li>
            <li className="nav-martin">
              <Link to="/contact">Contact</Link>
            </li>
           {/* <li className="nav-item dropdown"> 
              <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Dropdown
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="/">Action</a>
                <a className="dropdown-item" href="/">action2</a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="/">action3</a>
              </div>
            </li>
            <li className="nav-item active">
              <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
            </li>
            
            <li className="nav-item">
              <a className="nav-link disabled" href="/">Disabled</a>
            </li>
             */}
          </ul>
          <form className="form-inline my-2 my-lg-0">
            <input className="searchInputColor" type="search" placeholder=" Search" aria-label="Search" /> 
            <button className="btn btn-outline-primary searchBottonColor" style={{color: "#fff"}} type="submit">Search</button>
          </form>
        </div>
      </nav>
)