import React from 'react';
import "./css/style.css";

export const Contact = () => (
 <div>
    <div className="doubleline"></div>
      <div className="container-fluid text-center">
        <div className="row">
          <div className="col-lg-4">
             <div className="card">
               <div className="face">
                <div className="content">
                <img className="img-fluid" src={require('./assets/email.jpg')} width="400" height="75" alt="emial icon" />
                </div>
              </div>
              <div className="face">
                    <h4 className='h4'>Contact</h4>
                    <p><input type="text" name="name" className="k-textbox" placeholder="Your Name" /></p>
                    <p><input type="email" name="email" placeholder="Email address" /></p>
                    <p><textarea name="message" placeholder="Message" row="8"></textarea></p>
                    <a href="https://appdeveloperweb.com/contact" className='button'>Submit</a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
             <div className="card">
               <div className="face">
                <div className="content">
                <img className="img-fluid" src={require('./assets/email.jpg')} width="400" height="75" alt="an email icon" />
                </div>
              </div>
              <div className="face">
                    <h4 className='h4'>Info:</h4>
                    <p>Martin Perez<br />
                    Software Developer<br />
                    Los Angeles Ca. <br />
                    mperez6109@gmail.com<br />
                    linkedin.com/in/martin-perez-programmer/<br />
                    Phone: ***
                    </p>
                    <a href="https://appdeveloperweb.com/#/about" className='button'>View Resume</a>
              </div>
            </div>
          </div>        
        </div>
       </div>
       <div className="doubleline"></div>
      </div>
);