import React from 'react';
import "./css/style.css";
import { Grid, Cell } from 'react-mdl';
import { Container } from 'reactstrap';

export const About = () => (
		<Container>	
			<div>
				  <Grid>
				  	<Cell col = {4}>
					  	<div style =  {{ textAlign: 'Left'}} >
					  		<img src="http://appdeveloperweb.com/images/martinP-resize.jpg"
					  		     alt="martin perez and I like the data science"
					  		     style = {{ height: '200px'}}
					  		/>
					  	</div>
						  	<h2 style = {{paddingtop: '2em'}}>Martin Perez </h2>
						  	<h4 style = {{ color: 'grey' }}>SOFTWARE PROJECT MANAGER</h4>
						  	<hr style = {{ borderTop: '3px solid #833fb2', width: '50%'}} />
						  	<p>Results-driven Software Project Manager with extensive experience in leading cross-functional teams, managing the full project lifecycle, and delivering successful software solutions. Skilled in Agile and Waterfall methodologies, stakeholder negotiation, and technical problem-solving. Proficient in a wide range of programming languages and tools.</p>
						  	<hr style = {{ borderTop: '3px solid #833fb2', width: '50%'}} />
						  	<h5> Address </h5>
						  	<p>Los Angeles Ca.</p>
						  	<h5>Phone</h5>
						  	<p>*** *** ****</p>
						  	<h5>Email</h5>
						  	<p>mperez6109@gmail.com</p>
						  	<h5>Web</h5>
						  	<p><a href="https://appdeveloperweb.com" className = "customLinks">AppDeveloperWeb.com</a></p>
						  	<hr style = {{ borderTop: '3px solid #833fb2', width: '50%'}} />
				  	</Cell>

				  	<Cell className = "resume-right-col" col = {8}>
				  		<h2>Experience</h2>
						  	<p><strong>Independent Contractor</strong> ( <a href="https://iconcollective.edu">https://iconcollective.edu</a> , <a href="https://admdx.com/">https://admdx.com/</a> , <a href="https://ugraft.com/">https://ugraft.com/</a> , <a href="https://appdeveloperweb.com/laxairport/">https://laxairport.com/</a> , <a href="https://www.flypia.com/airport/flights/">https://www.flypia.com/airport/flights/</a> , <a href="http://lecoeurjp.com">https://lecoeurjp.com/</a> , <a href="https://peoriaymca.org/programs/adult-sports/">YMCA</a>, <a href="https://centralstatesmedia.com/">Central States Media</a> , <a href="https://kostivlaw.com/">EzLawPay</a>)<br />
								Infrastructure Project Manager | 01/01/2018 - Current
								<ul>				
										<li>Led and managed teams/projects comprising an average of four people/projects. Experience with Start-up, contractors, and negotiating with stakeholders.</li>
										<li>Successfully collaborated in a team environment or assembling the project team, and led teams with a variety of developers worldwide, and in the States.</li>
										<li>Knowledge of project management tools: JIRA, Confluence, Trello, and Asana.</li>
										<li>Knowledge and understanding of Waterfall / Agile / Scrum-oriented methodologies. (Velocity, Burn Down, Quality, monitoring of KPIs, etc.)</li>
										<li>Manage compliance with the agile methodology with team observation. Effectively used scrum meetings (daily standup, sprint, retrospective review, user stories, and planning).</li>
										<li>Developed a roadmap for the Marketplace, motivating and inspiring the engineering team to achieve ambitious goals.</li>
										<li>Ability to prioritize to meet tight deadlines and manage staff working on various assignments and projects within required budgets and deadlines.</li>
										<li>Responsible for managing/building the entire project ( SDLC ) life cycle from conceptualizing, strategic, planning, execution, and launch.</li>
										<li>Implementing third-party software for the management of business processes, data integration, and data quality. Integrated CRM, ERP, EDI, eCommerce, Google Analytics, Twilio, and Installed Certificates for SFTP.</li>
										<li>Participate in code reviews, code analysis, and identification of software risks.</li>
										<li>Ability to understand the client's problem and provide the best technical solution.</li>
										<li>Proficient in source code management tools (BitBucket, Github, and SVN).</li>
										<li>Expert with MS PowerPoint, Excel, MS Word, and the full MS Office(G-Suite).</li>
										<li>Prepare technical documentation, develop schemas, tables, testing, authentication, authorizations, scripts, stored procedures, views, triggers, complex functions, and relational databases.</li>
										<li>Designing databases (SQL, MySQL, PLSQL) and ensuring their stability, reliability, performance, and implementation based on the end user's requirements.</li>
										<li>Quickly and confidently design wireframes, flow charts, typography, and mock-ups.</li>
										<li>Themes and plugins, modules, and experience writing and consuming APIs.</li>
										<li>Bootstrap/Tailwind, HTML5, CSS3, JavaScript, PHP, Node JS, React JS, Vue JS, Redux, and multimedia.</li>
										<li>E-commerce (FunnelKonnekt, PayPal, Square, Shopify, Stripe, and CS-Cart).</li>
										<li>Data Science: Dashboards for agency executives, and Search engine optimization (SEO).</li>
										<li>In the latest project at Icon Collective School of Music, I was leading the ongoing development and maintenance of the CMS Admission build with WordPress and Gravity forms, ActiveCampaign, Email Automation, Stripe Pay System, and Google Analytics, using APIs for in real-time transfer data, supported the admissions CMS in all technical and reporting needs, and provided for the direct management and supervision of the Admissions Systems staff—reduced costs to up 30% by negotiating pricing, fees, and replacing outdated applications.</li>
										<li>Built financial management web applications, for example, an application for a law firm that initiates timely reviews of new legal cases, and sets approvers, permissions, and due dates with auto-email notifications. Assign the issue to a representative, submit it to the final approver, and accept or reject the case. I have developed a deep understanding of all metrics in the marketplace so I am always making data-driven decisions.</li>
										<li>Build web applications for healthcare to do image data analysis and full CNS imaging study services with structural MRI, amyloid PET, and DTI. Providing pharmaceutical companies with powerful information to improve clinical trial success. Experience in a HIPAA/Healthcare IT environment. Integration of third-party software to handle DICOM images and formats.</li>

								</ul>
								<strong>NASA/JPL/Caltech</strong><br />
								Web Programmer | May 2005 – November 2017<br />
								Designed, coded and modified websites, from layout to function and according to a client's specifications. Strived to create visually appealing sites that featured user-friendly design and clear navigation.
								<ul>
									<li>Developed iPhone and Android App resulting and received award from the NASA Inventions and Contributions Board.</li>
									<li>Tech Brief Magazine article an award for scientific and technical contributions to NASA.</li>
									<li>Developed video and audio players in JavaScript ensuring the use of the latest technology and universal access.</li>
									<li>Developed and managed electronic forms and workflows to automate business processes.</li>
									<li>Established Phoenix Mars mission mobile-friendly website in 2006.</li>
									<li>PHP programmer and Laravel framework.</li>
									<li>Object Oriented design and Programming.</li>
									<li>Proficiency with components and configuration for the LAMP stack.</li>
									<li>Build client websites with WordPress, and modify existing code as needed.</li>
									<li>Develop themes and plugins, and experience writing and using APIs.</li>
									<li>Performed all day-to-day aspects of MySQL database developer - including design, installation, queries upgrades, security, performance monitoring, and optimization.</li>
									<li>Developed web applications for the different events calendar.</li>
									<li>Applies consultative skills to assess user needs and communicate technology systems and solutions.</li>
									<li>Researches and evaluates the functionality of vendor software to meet user needs.</li>
									<li>Published video and news releases to NASA and JPL sites.</li>
									<li>JavaScript (Reactjs), Angular, Bootstrap, and regular expressions.</li>
									<li>Solid Understanding of HTML5/CSS3(SASS), jQuery, and Responsive Design.</li>
									<li>Strong working experience in solving Cross-Browser Compatibility.</li>
									<li>Produce ADA WCAG 2.0 A and AA(508) compliant web pages, and documents.</li>
									<li>Evaluates and troubleshoots software processes and programs.</li>
									<li>Utilized Google web analytics for metrics reports.</li>
									<li>Performed daily testing of site to ensure optimal levels of website functionality.</li>
									<li>Analyzed over website for customer ease of navigate and increased time on site.</li>
									<li>Implementation variable configurations for props, events and evars in JavaScript.</li>
									<li>Hands on weekly/monthly reporting across several channels, including websites, email, mobile, and mobile applications.</li>
									<li>Search engine optimization.</li>
									<li>Email marketing templates.</li>
									<li>Data Science: Development of project dashboards for agency executives.</li>
								</ul>
								</p>
						<hr style = {{ borderTop: '3px solid #833fb2', width: '50%'}} />
					  	<h2>Education</h2>
						  	<p>California State University | Los Angeles, CA<br />
							Bachelor of Science in Computer Science</p>
							<p>Associate of Science in Mathematics | Los Angeles, CA<br />
							Los Angeles City College</p>

						  	<hr style = {{ borderTop: '3px solid #833fb2', width: '50%'}} />

						  	<h2>Technical Skills</h2>
						  	<p><strong>Languages:</strong> Assembly Language, Algorithm Analysis, CSS3, Graphical User Interface, Networking, OOP, Perl, PHP, Security, Software Engineering, Multimedia, and XML.</p>
							<p><strong>Software:</strong> Agile, ActiveCampaign, Active Directory, Apache, AppScan, Asana, Bitbucket, ClickGuard, CRM, Design, DreamHost, Dropbox Sign, Drupal, Duda CMS, Figma, FTP, GIT, GIMP, Google Admin, Analytics, Tags, Search Console, AdSense, Captcha, Gravity Forms, iContact, JDK1.4, LAMP, LocalWP, Logo Design, MailChimp, Macromedia, Metrics, Netsuite, NPM,  pgAdmin4, OWASP ZAP, Photoshop, Postman, SSH, TortoiseSVN, Webpack, WIX, WordPress, Zappier, and 3D.</p>
							<p><strong>Systems:</strong> Windows, Mac, Linux, iOS, Android</p>
							<p><strong>Database:</strong> Artificial Intelligence (Programming in Logic and Lisp), Access, Oracle, SQL (MySQL), and PostgreSQL.</p>
							<p><strong>Assist Tech:</strong> ACCVerify, JAWS, Sort Site, and WindowEyes</p>						  		

				  	 </Cell>
				  </Grid>
				 </div>
	</Container>
);