import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, HashRouter } from 'react-router-dom';
import { Home } from './Home';
import { About } from './About';
import { Contact } from './Contact';
import { Services } from './Services';
import { Seo } from './Seo';
import { Disabilities } from './Disabilities';
import { Consulting } from './Consulting';
import { JSPage } from './JSPage';
import { JSEditor } from './JSEditor';
import { NoMatch } from './NoMatch';
import { Layout } from './components/Layout';
import { NavigationBar } from './components/NavigationBar';
import { Jumbotron } from './components/Jumbotron';
import { Footer } from './components/Footer';
import createBrowserHistory from 'history/createBrowserHistory'

const history = createBrowserHistory()

class App extends Component {
   componentDidMount() {
    const script = document.createElement("script");    
          script.async = true;    
          script.src = "./js/index.js";    
          this.div.appendChild(script);  
        }
  render() {
    return (

      <React.Fragment>
        <HashRouter>
          <NavigationBar />
          <Jumbotron />
          <Layout>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/about" component={About} />
              <Route path="/contact" component={Contact} />
              <Route path="/services" component={Services} />
              <Route path="/seo" component={Seo} />
              <Route path="/disabilities" component={Disabilities} /> 
              <Route path="/consulting" component={Consulting} />
              <Route path="/JSPage" component={JSPage} />
              <Route path="/JSEditor" component={JSEditor} />
              <Route component={NoMatch} />
            </Switch>
          </Layout>
            <div className="App" ref={el => (this.div = el)}>        
              
              {/* Script is inserted here */}
            </div>
          <Footer />
        </HashRouter>
      </React.Fragment>
    );
  }
}

export default App;
