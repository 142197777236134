import React from 'react';
import { Jumbotron as Jumbo, Container } from 'react-bootstrap';
import styled from 'styled-components';
import boatImage from '../assets/J4o.gif';

const Styles = styled.div`
  .jumbo {
    background: url(${boatImage}) no-repeat fixed bottom;
    background-size: cover;
    font-family: nunito sans,Arial,sans-serif;
    color: #fff;
    height: 200px;
    position: relative;
    z-index: -2;
  }

  .overlay {
    
    opacity: 0.6;
    position: absolute;
    font-family: nunito sans,Arial,sans-serif;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
`;

export const Jumbotron = () => (
  <Styles>
    <Jumbo fluid className="jumbo">
      <div className="overlay"></div>
      <Container>
        <h3>Web Applications, Database, PHP Programming</h3>
        <p>by Martin Perez</p>
      </Container>
    </Jumbo>
  </Styles>
)
