import React from 'react';
import "./css/style.css";
import { Container, Row, Col } from 'reactstrap';

export const Consulting = () => {
 return (
    <Container>
	    <div class="row">
		    <div class="col col-lg-4">
		    </div>
		    <div class="col-md-auto">
		       <div>
    			<ul className="list-group">
				  <li className="list-group-item "><strong>Consulting Services</strong></li>
				  <li className="list-group-item list-group-item-light">Companies that are currently working with a vendor and would like an audit performed to ensure they are receiving a high-quality project.</li>
				  <li className="list-group-item">I specialize in tech audits and due diligence for our enterprise clients. </li>
				  <li className="list-group-item list-group-item-light">I build an executive-level report card that demonstrates how every major category performs and provides a deep dive into development processes to ensure methodologies are up to date.</li>
				  <li className="list-group-item"><center><img className="img-fluid container-div" src={require('./assets/it-audit.png')} alt="Consulting Services" title="Consulting Services"/></center></li>
				</ul>
    <p></p>
  </div>
		    </div>
		    <div class="col col-lg-4">
		    </div>
	  	</div>
        

    </Container>
  );
}