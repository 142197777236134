import React from 'react';
import '../css/navbar.css';

export const Footer = () => (
  <nav className="font-small pt-4 mt-4 page-footer" style={{backgroundColor: "#2E2E2E"}}>

    <div className="row">
      <div className="col-sm-8"><h5 className="title txt-margin-title">Let's talk</h5>
      <p className="txt-margin">Wanna get in touch or talk about a project? Feel free to contact me via email at 
            mperez6109@gmail.com
            <br />
            or drop a line in the form at the contact page.
          </p></div>
      <div className="col-sm-4">
            <h5 className="title txt-margin-title">Social Media</h5>
            <a href="https://www.linkedin.com/in/martin-perez-programmer/" class="btn-floating btn-lg btn-li" type="button" role="button"><i class="fa fa-linkedin fa-fw red-text"></i></a>
            <a href="https://stackexchange.com/users/17913153/martin" class="btn-floating btn-lg btn-so" type="button" role="button"><i class="fa fa-stack-overflow"></i></a>
            <a href="https://github.com/mperez6109" class="btn-floating btn-lg btn-git" type="button" role="button"><i class="fa fa-github"></i></a>
            <a href="https://www.facebook.com/mperez6109" class="btn-floating btn-lg btn-fb" type="button" role="button"><i class="fa fa-facebook"></i></a>
            <a href="https://appdeveloperweb.com/contact" class="btn-floating btn-lg btn-email" type="button" role="button"><i class="fa fa-envelope"></i></a>      
      </div>
      <div className="col-9 txt-copyright container-fluid"> &copy; {new Date().getFullYear()} Copyright: Martin's Portfolio.</div>
    </div>
  </nav>
)
