import React from 'react';
import { Container, Row, Col } from 'reactstrap';

export const Seo = () => {
 return (
    <Container>
	    <div class="row">
		    <div class="col col-lg-4">
		    </div>
		    <div class="col-md-auto">
		      <ul className="list-group">
				  <li className="list-group-item "><strong>Successful SEO:</strong></li>
				  <li className="list-group-item list-group-item-light">Crawl accessibility so engines can read your website</li>
				  <li className="list-group-item">Compelling content that answers the searcher’s query</li>
				  <li className="list-group-item list-group-item-light">Keyword optimized to attract searchers & engines</li>
				  <li className="list-group-item">Great user experience including a fast load speed and compelling UX</li>
				  <li className="list-group-item list-group-item-light">Share-worthy content that earns links, citations, and amplification</li>
				  <li className="list-group-item">Title, URL, & description to draw high CTR in the rankings</li>
				  <li className="list-group-item list-group-item-light">Snippet/schema markup to stand out in SERPs</li>
				</ul>
		    </div>
		    <div class="col col-lg-4">
		    </div>
	  	</div>
        <Row>
	        <Col xs="3"></Col>
	        <Col xs="auto">
	        	<div className="content">
	                <img className="img-fluid" src={require('./assets/Mozlows-01-outline.svg')} width="600" height="300" alt="Search Engine Development"/>
	            </div>
		  	</Col>
	        <Col xs="3"></Col>
        </Row>

    </Container>
  );
}