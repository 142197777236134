import React from 'react';
import "./css/style.css";
import { Container, Row, Col } from 'reactstrap';

export const Disabilities = () => {
 return (
    <Container>
	    <div class="row">
		    <div class="col col-lg-4">
		    </div>
		    <div class="col-md-auto">
		       <div>
    			<ul className="list-group">
				  <li className="list-group-item "><strong>People with Disabilities</strong></li>
				  <li className="list-group-item list-group-item-light">I am committed to making your web application accessible to everyone, including people with disabilities.</li>
				  <li className="list-group-item">Developed for hearing-impaired people, closed captions to your videos are useful in a variety of situations. Ensure your captions are available and accurate.</li>
				  <li className="list-group-item list-group-item-light">Text zoom allows low-vision users to increase only the size of the text up to 200% without the loss of information and minimum scrolling.</li>
				  <li className="list-group-item">I audit, test with disabled users, and fix accessibility on your web application. Proven strategies to achieve compliance whether WCAG, Section 508, or VPAT.</li>
				  <li className="list-group-item list-group-item-light">I remediate documents created in any format including: Word, Excel, PowerPoint, and PDF.</li>
				  <li className="list-group-item">Every user deserves a first-rate digital experience. I focus on the accessibility of your digital product.</li>
				  <li className="list-group-item"><center><img className="img-fluid container-div" src={require('./assets/accessibilitykeysonkeyboard.jpg')} alt="Consulting Services" title="Consulting Services"/></center></li>
				</ul>
    <p></p>
  </div>
		    </div>
		    <div class="col col-lg-4">
		    </div>
	  	</div>
        
    </Container>
  );
}