import React from "react";
import "./css/style.css";
import { Link } from 'react-router-dom';

export const Home = () => {
  return (
    <div>
    <div className="doubleline" style = {{ color: 'red', fontWeight: 'bold' }}>Howdy! Adding more content when time permits :)</div>
      <div className="container-fluid text-center">
        <div className="row">
          <div className="col-lg-4">
             <div className="card">
               <div className="face">
                <div className="content">
                <img className="img-fluid" src={require('./assets/seo-company400x221.jpg')} width="400" height="200" alt="Search Engine Development"/>
                </div>
              </div>
              <div className="face">
                    <h4 className='h4'>Search Engine Development</h4>
                    <p>Your visitors and Search Engines will both very much thank you.</p>
                    <Link to ='/Seo' className='button' >Read more</Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
             <div className="card">
               <div className="face">
                <div className="content">
                <img className="img-fluid" src={require('./assets/ecommerce-Technologies400x250.jpg')} width="400" height="200" alt="E-Commerce, squareup, paypal" />
                </div>
              </div>
              <div className="face">
                    <h4 className='h4'>E-Commerce</h4>
                    <p>Launching your store, and grow your traffic and sales.</p>
                    <a href ='https://appdeveloperweb.com/greenstop/' className='button' >Read more</a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
             <div className="card">
               <div className="face">
                <div className="content">
                <img className="img-fluid" src={require('./assets/dataScience400x200.jpg')} width="400" height="200" alt="Data Science" />
                </div>
              </div>
              <div className="face">
                  <h4 className='h4'>Data Science</h4>
                  <p>Extracting value from massive amounts of data to drive business growth and efficiency.</p>
                  <a href ='https://appdeveloperweb.com/statistics/' className='button' >Read more</a>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
             <div className="card">
               <div className="face">
                <div className="content">
                <img className="img-fluid" src={require('./assets/programming-plc-languages400x200.jpg')} width="400" height="200" alt="People with Disabilities" />
                </div>
              </div>
              <div className="face">
                  <h4 className='h4'>People with Disabilities</h4>
                  <p>Committed to making your web application accessible to everyone, including people with disabilities.</p>
                  <Link to ='/Disabilities' className='button' >Read more</Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
             <div className="card">
               <div className="face">
                <div className="content">
                <img className="img-fluid" src={require('./assets/software-Companies-755x420.jpg')} width="400" height="200" alt="Multimedia" />
                </div>
              </div>
              <div className="face">
                  <h4 className='h4'>Multimedia</h4>
                  <p>Build galleries, video(streamming) and audio players in JavaScript ensuring the use of the latest technology and universal access.</p>
                  <a href ='https://appdeveloperweb.com/multimedia/' className='button' >Read more</a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
             <div className="card">
               <div className="face">
                <div className="content">
                <img className="img-fluid" src={require('./assets/software.jpg')} width="400" height="200" alt="Consulting Services" />
                </div>
              </div>
              <div className="face">
                  <h4 className='h4'>Consulting Services</h4>
                  <p>Companies that are currently working with a vendor and would like an audit performed to ensure they are receiving a high-quality project.</p>
                  <Link to ='/Consulting' className='button' >Read more</Link>
              </div>
            </div>
          </div>         
        </div>
       </div>
       <div className="doubleline"></div>
      </div>
   );
};