import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import "./css/style.css";
import { Card, CardTitle, CardText, CardActions, Button, CardMenu, IconButton } from 'react-mdl';
import { Link } from 'react-router-dom';

export const Services = (props) => {
  return (
    <Container>
      
      <Row>
        <Col sm={{ size: 'auto' }}>
        	<Card shadow = {5}>
				<CardTitle  className = 'frontImage' style = {{ color: '#fff', height: '176px'}}>
						Frontend 
					</CardTitle>
					<CardText>
						I strived to create visually appealing web applications that featured user-friendly design and clear navigation. 
					</CardText>
					<CardActions border>
						<Button colored><a href="#/JSPage">JavaScript</a></Button>
						<Button colored>CSS3</Button>
						<Button colored>React JS</Button>
					</CardActions>
					<CardMenu style = {{ color: '#fff'}}>
					<IconButton name = "share" />
					</CardMenu>
			</Card>
        </Col>
        <Col sm={{ size: 'auto' }}>
        	<Card shadow = {5}>
				<CardTitle  className = 'backendImage' style = {{ color: '#fff', height: '176px'}}>
					Backend 
					</CardTitle>
					<CardText>
						I put together well-written code that helps to create advanced applications which are fit for purpose, functionally correct.
					</CardText>
					<CardActions border>
						<Button colored>PHP</Button>
						<Button colored>SEO</Button>
						<Button colored>Data Science</Button>
					</CardActions>
					<CardMenu style = {{ color: '#fff'}}>
					<IconButton name = "share" />
					</CardMenu>
			</Card>
		</Col>
        <Col sm={{ size: 'auto' }} >
			<Card shadow = {5}>

					<CardTitle className = 'ecommerceImage' style = {{ color: '#fff', height: '176px'}} >

					E-commerce</CardTitle>
					<CardText>
						Develop, maintain, and execute comprehensive ecommerce platform regression testing scripts.
					</CardText>
					<CardActions border>
						<Link to="https://appdeveloperweb.com/greenstop/"><Button colored>PayPal</Button></Link>
						<Link to="https://appdeveloperweb.com/greenstop/"><Button colored>Square</Button></Link>
						<Link to="https://appdeveloperweb.com/greenstop/"><Button colored>Shopify</Button></Link>
					</CardActions>
					<CardMenu style = {{ color: '#fff'}}>
					<IconButton name = "share" />
					</CardMenu>
			</Card>
        </Col>
      </Row>

       <p></p>
        <p>This is mostly about my experience.</p>
    <p>Web Developer specializing in front and back-end development. More than fifteen years of experience with all stages of the development cycle for dynamic web projects. Well-versed in numerous programming languages including PHP, JavaScript, MySQL, and Security. Strong background in project management and customer relations.
    </p>
    </Container>
  );
}
